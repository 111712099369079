import { ActorType } from 'app/redux/modules/RemoteSession/v2/actor';
import {
  ENTRY_FORM_POST_ADDRESS_REQUEST,
  ENTRY_FORM_PUT_FORM_REQUEST,
  ENTRY_FORM_QUESTIONARE_STEP_ADD,
  ENTRY_FORM_QUESTIONARE_STEP_REMOVE,
  ENTRY_FORM_UPDATE_VALUE,
} from 'redux/modules/EntryForm/constants';
import { SET_SERVICES } from 'redux/modules/ServiceSelection/constants';
import { PUT_BROADBAND_REQUEST } from 'redux/modules/Broadband/constants';
import { POST_APPLICATION_PROPERTY_METER_REQUEST } from 'redux/modules/Energy/ProductSelectionForm/constants';
import {
  APP_POST_APPLICATION_COMPLETE_REQUEST,
  APP_PUT_REMOTE_APPLICATION_SUPPORTING_REQUEST,
  EDIT_ORDER,
} from 'redux/modules/App/constants';
import { ACCEPT_QUOTES_REQUEST } from 'redux/modules/Energy/Quote/constants';
import {
  GET_APPLICATION_SUMMARY_REQUEST,
  ORDER_CONFIRMED,
  ORDER_CONFIRMED_NO_PAYMENT,
  ORDER_PREVIEW_CONTINUE,
  REMOVE_PRODUCT_REQUEST,
} from 'app/redux/modules/OrderSummary/types';
import { POST_HOME_INSURANCE_RENEWAL_DATE_REQUEST } from 'redux/modules/HomeInsuranceRenewal/constants';
import { POST_MOBILE_CONTRACT_RENEWAL_DATES_REQUEST } from 'redux/modules/MobileContractRenewal/constants';
import { BACK_NAVIGATION } from 'redux/modules/Navigation/constants';
import {
  CONFIRM_SELECTION_REQUEST,
  ELIGIBILITY_CHECK_REQUEST,
} from 'redux/modules/GoldBenefit/constants';
import {
  ACCEPT_BOILER_COVER_QUOTE_REQUEST,
  UPDATE_BOILER_COVER_REQUEST,
  GET_BOILER_COVER_QUOTE_REQUEST,
} from 'redux/modules/BoilerCover/constants';
import {
  BILLPROT_ACCEPT_REQUEST,
  BILLPROT_FETCH_QUOTE_REQUEST,
} from 'redux/modules/BillIncomeProtector/types';
import { POST_FUNDING_PAYMENT_CARD_REQUEST } from 'redux/modules/PaymentForm/constants';
import { CASHBACK_CARD_ADDED } from 'app/redux/modules/Cashback/constants.js';
import { LANDING_SET_PROPERTY_OWNER } from 'app/redux/modules/EntryOrganic/types';
import { SET_ADDRESS_SELECTION } from 'app/redux/modules/AddressPickerNew/types';
import { getActionName } from 'app/redux/modules/AddressPickerNew/utils';
import { GET_QUOTES_REQUEST } from 'redux/modules/Energy/Quote/constants';

export const blacklisted = [
  GET_APPLICATION_SUMMARY_REQUEST,
  POST_FUNDING_PAYMENT_CARD_REQUEST,
  ORDER_CONFIRMED,
  UPDATE_BOILER_COVER_REQUEST,
  CONFIRM_SELECTION_REQUEST,
  ELIGIBILITY_CHECK_REQUEST,
  ENTRY_FORM_UPDATE_VALUE,
  ENTRY_FORM_POST_ADDRESS_REQUEST,
  ENTRY_FORM_QUESTIONARE_STEP_ADD,
  ENTRY_FORM_QUESTIONARE_STEP_REMOVE,
  ENTRY_FORM_PUT_FORM_REQUEST,
  SET_SERVICES,
  PUT_BROADBAND_REQUEST,
  POST_APPLICATION_PROPERTY_METER_REQUEST,
  ACCEPT_QUOTES_REQUEST,
  EDIT_ORDER,
  REMOVE_PRODUCT_REQUEST,
  ORDER_PREVIEW_CONTINUE,
  ORDER_CONFIRMED_NO_PAYMENT,
  APP_POST_APPLICATION_COMPLETE_REQUEST,
  ORDER_PREVIEW_CONTINUE,
  POST_HOME_INSURANCE_RENEWAL_DATE_REQUEST,
  POST_MOBILE_CONTRACT_RENEWAL_DATES_REQUEST,
  BACK_NAVIGATION,
  BILLPROT_FETCH_QUOTE_REQUEST,
  GET_QUOTES_REQUEST,
];

const mainPeerOnly = [
  ACCEPT_BOILER_COVER_QUOTE_REQUEST,
  BILLPROT_ACCEPT_REQUEST,
  GET_BOILER_COVER_QUOTE_REQUEST,
  CASHBACK_CARD_ADDED,
  LANDING_SET_PROPERTY_OWNER,
  getActionName(SET_ADDRESS_SELECTION, 'LANDING'),
];
const customerOnly = [APP_PUT_REMOTE_APPLICATION_SUPPORTING_REQUEST];

export const actionsToFilter = (mainPeer, actor) => {
  let actions = [...blacklisted];

  // filter if not a CUSTOMER
  if (actor !== ActorType.CUSTOMER) {
    actions = actions.concat(customerOnly);
  }

  // filter if not a mainPeer
  if (actor !== mainPeer) {
    actions = actions.concat(mainPeerOnly);
  }

  return actions;
};
